<template>
    <div id="container">
        <h1>Welcome to the Best Salon and Luxury Day Spa in Portage</h1>
        <p>
            Welcome to Centre Spa & Wellness, where we encourage you to “find your Centre” at our new destination luxury day spa, hair salon, and wellness center.
            Our full-service spa offers an extensive selection of services, including massage therapy, wellness counseling, makeup services, and manicure and pedicure treatments. Covering our guests’ basic needs and luxurious wants, our 5,850 square-foot upscale spa features a relaxing environment, top-notch guest assistance and services delivered by an experienced team of the area’s best stylists, estheticians, and massage therapists.
        </p>
        <p>
            In addition to the best spa treatments in Portage, we carry a number of premium product lines, including Aveda, Pevonia, and Bumble and bumble. This unique combination of high-quality products and first-rate spa services positions Centre Spa & Wellness 
            as the area’s best salon and spa. Driven by our mission to help our guests achieve a happier and healthier lifestyle, we strive to exceed our customers’ expectations and, most importantly, help them find their Centre.
        </p>
    </div>
</template>

<script>
    export default {
        name : "Welcome"
    }
</script>

<style scoped>
#container{
    margin: 2vh 3vw 2vh 3vw;
}
h1{
    padding: 1vh;
    font-size: 2.2rem;
    color:rgba(208, 233, 97, 0.8) ;
}
p{
    color: grey;
}
</style>